body {
  font-family: '小塚ゴシック Pr6N R', sans-serif;
}

.topButton2 {
  overflow: hidden;
  background: #94B56A;
  height: 44px;
  padding: 0;
  line-height: 44px;
}

.topButton2 > li {
  float: left;
  position: relative;
  width: 50%;
  text-align: center;
  list-style: none;

}

.topButton2 > li:before, .topButton2 > li:after {
  position: absolute;
  top: 0;
}

.topButton2 > li:before {
  width: 44px;
  height: 44px;
  right: -43px;
  background: inherit;
}

.topButton2 > li:after {
  right: -44px;
  border: 22px solid;
  border-color: transparent #94B56A #94B56A transparent;
}

.topButton2 > li.active {
  background: -webkit-linear-gradient(top, #fae7f8, #fae7f8 50%, #fae7f8 50%, #fae7f8);
  font-size: 20px;
  font-family: '小塚ゴシック Pr6N R', sans-serif;
  color: #92668C;
}

.topButton2 > li.right {

  font-size: 20px;
  font-family: '小塚ゴシック Pr6N R', sans-serif;
  color: #ffffff;
}

.topButton2 > li.active:before, .topButton2 > li.active:after {
  content: '';
}

