.radio-optionR1 {
  margin-bottom: 5px;
  display: block;
  padding-left: 15px;
  /* Ensures each radio option is on a new line */
}

.radio-optionR2 {
  margin-bottom: 5px;
  display: block;
  padding-left: 15px;
  /* Ensures each radio option is on a new line */
}

.radio-optionR3 {
  margin-bottom: 5px;
  display: block;
  padding-left: 15px;
  /* Ensures each radio option is on a new line */
}

.radio-optionR4 {
  margin-bottom: 5px;
  display: block;
  padding-left: 15px;
  /* Ensures each radio option is on a new line */
}

.radio-optionR5 {
  margin-bottom: 5px;
  display: block;
  padding-left: 15px;
  /* Ensures each radio option is on a new line */
}

.replay-label {
  display: inline-block;
  padding: 5px 60px;
  width: 45px;
  border: 2px solid;
  cursor: pointer;
  position: relative;
  padding-left: 35px;
  font-family: 'Futura', sans-serif;
  font-size: 20px;
  /* Increased font size */
  /* Space for the circle inside */
}

/* Styling for each option */
#optionR1+.replay-label {
  border-color: #94B56A;
  color: #94B56A;
}

#optionR2+.replay-label {
  border-color: #92668C;
  color: #92668C;
}

#optionR3+.replay-label {
  border-color: #DEBED1;
  color: #DEBED1;
}

#optionR4+.replay-label {
  border-color: #127254;
  color: #127254;
}

#optionR5+.replay-label {
  border-color: #835323;
  color: #835323;
}





.radio-optionR1 input[type="radio"] {
  display: none;
}

.radio-optionR2 input[type="radio"] {
  display: none;
}

.radio-optionR3 input[type="radio"] {
  display: none;
}

.radio-optionR4 input[type="radio"] {
  display: none;
}

.radio-optionR5 input[type="radio"] {
  display: none;
}


/* Custom radio button inside the label */
.radio-optionR1 input[type="radio"]+.replay-label:before {
  content: '';
  position: absolute;
  left: 10px;
  top: 50%;
  /* Position inside the label */
  transform: translateY(-50%);
  height: 15px;
  width: 15px;
  /* Size of the circle */
  border: 2px solid #94B56A;
  /* Color of your choice */
  border-radius: 50%;
  background-color: white;
}

.radio-optionR2 input[type="radio"]+.replay-label:before {
  content: '';
  position: absolute;
  left: 10px;
  top: 50%;
  /* Position inside the label */
  transform: translateY(-50%);
  height: 15px;
  width: 15px;
  /* Size of the circle */
  border: 2px solid #92668C;
  /* Color of your choice */
  border-radius: 50%;
  background-color: white;
}

.radio-optionR3 input[type="radio"]+.replay-label:before {
  content: '';
  position: absolute;
  left: 10px;
  top: 50%;
  /* Position inside the label */
  transform: translateY(-50%);
  height: 15px;
  width: 15px;
  /* Size of the circle */
  border: 2px solid #DEBED1;
  /* Color of your choice */
  border-radius: 50%;
  background-color: white;
}

.radio-optionR4 input[type="radio"]+.replay-label:before {
  content: '';
  position: absolute;
  left: 10px;
  top: 50%;
  /* Position inside the label */
  transform: translateY(-50%);
  height: 15px;
  width: 15px;
  /* Size of the circle */
  border: 2px solid #127254;
  /* Color of your choice */
  border-radius: 50%;
  background-color: white;
}

.radio-optionR5 input[type="radio"]+.replay-label:before {
  content: '';
  position: absolute;
  left: 10px;
  top: 50%;
  /* Position inside the label */
  transform: translateY(-50%);
  height: 15px;
  width: 15px;
  /* Size of the circle */
  border: 2px solid #835323;
  /* Color of your choice */
  border-radius: 50%;
  background-color: white;
}




.radio-optionR1 input[type="radio"]:checked+.replay-label:before {
  background-color: #94B56A;
  /* Same color as the border */
}

.radio-optionR2 input[type="radio"]:checked+.replay-label:before {
  background-color: #92668C;
  /* Same color as the border */
}

.radio-optionR3 input[type="radio"]:checked+.replay-label:before {
  background-color: #DEBED1;
  /* Same color as the border */
}

.radio-optionR4 input[type="radio"]:checked+.replay-label:before {
  background-color: #127254;
  /* Same color as the border */
}

.radio-optionR5 input[type="radio"]:checked+.replay-label:before {
  background-color: #835323;
  /* Same color as the border */
}