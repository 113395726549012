/* Popup container */
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Popup content */
.popup-content {

  padding: 0px;
  border-radius: 0px;
  text-align: center;
  position: relative;
}

/* Popup image */
.popup img {
  max-width: 100%;
  max-height: 100%;
}

/* Close button */
.close-button {
  position: absolute;
  top: 0%;
  right: 5%;
  font-size: 50px;
  cursor: pointer;
  color: rgb(255, 255, 255);
}