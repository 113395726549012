.records-options {
  display: flex;
  align-items: center;
}

.record-label {
  display: inline-block;
  padding: 0px 10px;
  cursor: pointer;
  margin-left: 0px;
  /* Space between radio options */
}

.record-optionR1 {
  margin-bottom: 5px;
  display: block;
  padding-left: 0px;
  /* Ensures each radio option is on a new line */
}


.record-optionR2 {
  margin-bottom: 5px;
  display: block;
  padding-left: 0px;
  /* Ensures each radio option is on a new line */
}

.records-optionR1 input[type="radio"] {
  display: none;
}

.records-optionR2 input[type="radio"] {
  display: none;
}

.records-optionR1 input[type="radio"]+.record-label:before {
  content: '';
  position: absolute;
  left: 0px;
  top: 50%;
  /* Position inside the label */
  transform: translateY(-50%);
  height: 15px;
  width: 15px;
  /* Size of the circle */
  border: 2px solid #92668C;
  /* Color of your choice */
  border-radius: 50%;
  background-color: white;
}

.records-optionR2 input[type="radio"]+.record-label:before {
  content: '';
  position: absolute;
  left: 0px;
  top: 50%;
  /* Position inside the label */
  transform: translateY(-50%);
  height: 15px;
  width: 15px;
  /* Size of the circle */
  border: 2px solid #127254;
  /* Color of your choice */
  border-radius: 50%;
  background-color: white;
}

.records-optionR1 input[type="radio"]:checked+.record-label:before {
  background-color: #92668C;
  /* Same color as the border */
}

.records-optionR2 input[type="radio"]:checked+.record-label:before {
  background-color: #127254;
  /* Same color as the border */
}

#optionR1+.record-label {
  border-color: #92668C;
  color: #92668C;
}

#optionR2+.record-label {
  border-color: #127254;
  color: #127254;
}

.record-label {
  display: inline-block;
  padding: 5px 60px;
  width: 40px;
  cursor: pointer;
  position: relative;
  padding-left: 25px;
  font-family: 'Futura', sans-serif;
  font-size: 20px;
  /* Increased font size */
  /* Space for the circle inside */
}