body {
  font-family: '小塚ゴシック Pr6N R', sans-serif;
}

.container {
  max-width: 400px;
  margin: 0 auto;
  margin-bottom: 0px;
}

.s6descriptions {
  text-align: left;
  margin-top: 30px;
  margin-bottom: 5px;
  padding-left: 15px;
  /* Space below the avatar section */
}

.purpleText {
  font-family: '小塚ゴシック Pr6N R', sans-serif;
  color: #92668C;
  font-size: 20px;
  margin-bottom: 0px;
}

.greenText {
  font-family: '小塚ゴシック Pr6N R', sans-serif;
  color: #94B56A;
  font-size: 20px;
  margin-bottom: 0px;
}

.finished-image-container {
  width: 90%;
  margin-top: 0px;
  /* Each section takes up 50% of the container */
  padding: 15px;
  /* Additional styling as needed */
}



.finished-image-container img {
  margin-top: 0px;
  width: 90%;
  /* Makes the image responsive */

  /* Maintains aspect ratio */
}

.finished-image-container2 img {
  margin-top: 0px;
  width: 50%;
  /* Makes the image responsive */

  /* Maintains aspect ratio */
}

.s6descriptions2 {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 5px;
  padding-left: 15px;
  /* Space below the avatar section */
}

.s6description-text-2b {
  font-family: '小塚ゴシック Pr6N R', sans-serif;
  color: #1C75BC;
  font-size: 20px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.s6description-text-2g {
  font-family: '小塚ゴシック Pr6N R', sans-serif;
  color: #94B56A;
  font-size: 20px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.s6description-text-2p {
  font-family: '小塚ゴシック Pr6N R', sans-serif;
  color: #92668C;
  font-size: 20px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.s6descriptions2 a {
  font-family: '小塚ゴシック Pr6N R', sans-serif;
  color: #94B56A;
  font-size: 20px;
  margin-top: 0px;
  margin-bottom: 0px;
  text-decoration-line: none;
}