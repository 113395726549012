body {
  font-family: '小塚ゴシック Pr6N R', sans-serif;
}

.containerStep2 {
  max-width: 100%;
  margin: 0 auto;
  margin-bottom: 0px;
}

.avatars {
  display: flex;
  justify-content: space-between;
  /* Distribute space between items */
  align-items: center;
  padding: 0px;
  /* Adjust padding to control distance from the container edges */
  margin-top: 15px;
  /* Space from the upper div */
}

.avatar {
  width: calc(50% - 10px);
  /* Subtract the desired space between the images */
  height: auto;
  /* Maintain aspect ratio */
  margin: 0 0px;

  /* Add margin to create space between the images */
}

.descriptions2 {
  text-align: left;
  margin-top: 30px;
  margin-bottom: 0;
  padding-left: 15px;
  /* Space below the avatar section */
}

.description-text2 {
  font-family: '小塚ゴシック Pr6N R', sans-serif;
  color: #127254;
  font-size: 20px;
  margin-bottom: 5px;

  /* Space between each text if needed */
}

.custom-file-uploads2 {
  cursor: pointer;
  /* Additional styling for the label */
}

.custom-file-uploads2 img {
  max-width: 58%;
  /* Maximum width of the image button */
  height: auto;
  /* Maintain the aspect ratio of the image */
  display: block;
  margin-top: 0px;
  margin-bottom: 10px;
  padding-left: 15px;
}

/* Rest of your CSS */

.file-upload {
  display: none;
}

.upload-container {
  text-align: left;
  width: 60%;
  margin-top: 0px;
  margin-bottom: 5px;
}

#preview-area img {}

.input-container {
  padding-left: 15px;
  padding-right: 15px;
  /* Styles for the overall container */
}

.input-row {
  display: flex;
  justify-content: space-between;
  /* Adjust space between fields */
  margin-bottom: 20px;
  /* Space between rows */
}

.input-field1 {
  flex-basis: 48%;
  /* Adjust the width of each input field */
  display: flex;
  flex-direction: column;
}

.input-field2 {
  flex-basis: 48%;
  /* Adjust the width of each input field */
  display: flex;
  flex-direction: column;
}

.input-field3 {
  flex-basis: 48%;
  /* Adjust the width of each input field */
  display: flex;
  flex-direction: column;
}

.input-field4 {
  flex-basis: 48%;
  /* Adjust the width of each input field */
  display: flex;
  flex-direction: column;
}

.input-field1 label {
  margin-bottom: 5px;
  text-align: left;
  /* Aligns the text of the label to the left */
  display: block;
  font-family: '小塚ゴシック Pr6N R', sans-serif;
  color: #94B56A;
  font-size: 15px;
}

.input-field2 label {
  margin-bottom: 5px;
  text-align: left;
  /* Aligns the text of the label to the left */
  display: block;
  color: #92668C;
  font-size: 15px;
}

.input-field3 label {
  margin-bottom: 5px;
  text-align: left;
  /* Aligns the text of the label to the left */
  display: block;
  color: #F7941D;
  font-size: 15px;
}

.input-field4 label {
  margin-bottom: 5px;
  text-align: left;
  /* Aligns the text of the label to the left */
  display: block;
  color: #835323;
  font-size: 15px;
}



.input-field1 input {
  padding: 8px;
  border: 2px solid #94B56A;
  /* Example border style */
  border-radius: 0px;
  color: #94B56A;
  height: 20px;
  font-size: 18px;
  width: 90%;
  /* Fixed height for input fields */
  /* Example border radius */
}

.input-field2 input {
  padding: 8px;
  border: 2px solid #92668C;
  /* Example border style */
  border-radius: 0px;
  color: #92668C;
  height: 20px;
  font-size: 18px;
  width: 90%;
  /* Example border radius */
}

.input-field3 input {
  padding: 8px;
  border: 2px solid #F7941D;
  color: #F7941D;
  height: 20px;
  /* Example border style */
  border-radius: 0px;
  font-size: 18px;
  width: 90%;
  /* Example border radius */
}

.input-field4 input {
  padding: 8px;
  border: 2px solid #835323;
  color: #835323;
  height: 20px;
  font-size: 18px;
  width: 90%;
  /* Example border style */
  border-radius: 0px;
  /* Example border radius */
}


.input-field4 input[type="date"] {
  -webkit-appearance: none;
  /* Specific for WebKit browsers */
  appearance: none;
  background-color: white;
  /* Example background color */
  /* Additional specific styles as needed */
}

/* Add any additional styles as needed */