body {
  font-family: '小塚ゴシック Pr6N R', sans-serif;
}

.topButton {
  overflow: hidden;
  background: #f7fdee;
  height: 44px;
  padding: 0;
  line-height: 44px;
}

.topButton > li {
  float: left;
  position: relative;
  width: 50%;
  text-align: center;
  list-style: none;

}

.topButton > li:before, .topButton > li:after {
  position: absolute;
  top: 0;
}

.topButton > li:before {
  width: 44px;
  height: 44px;
  right: -43px;
  background: inherit;
}

.topButton > li:after {
  right: -44px;
  border: 22px solid;
  border-color: transparent #f7fbf1 #f7fbf1 transparent;
}

.topButton > li.active {
  background: -webkit-linear-gradient(top, #92668C, #92668C 50%, #92668C 50%, #92668C);
  font-size: 20px;
  font-family: '小塚ゴシック Pr6N R', sans-serif;
  color: #ffffff;
}

.topButton > li.right {

  font-size: 20px;
  font-family: '小塚ゴシック Pr6N R', sans-serif;
  color: #94B56A;
}

.topButton > li.active:before, .topButton > li.active:after {
  content: '';
}

