.App {
  text-align: center;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.logo-area {
  width: 100%;
  position: sticky;
  background-color: white;
  /* Sticky position based on the container's natural position */
  top: 0;
  /* Aligns to the top when it becomes 'sticky' */
  z-index: 1000;
  /* Ensures it stays above other content */
}

.logo-container {

  /*height: 50px;*/
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-left: 20px;

}

.logo {
  width: 60%;
  /* Adjust the width or height as needed to maintain aspect ratio */
}



.text-links-section {
  font-family: '小塚ゴシック Pr6N R', sans-serif;
  text-align: left;
  margin-top: 0px;
  padding-left: 8%;
  padding-right: 50%;
  margin-bottom: 0px;
  /* Adjust overall padding as needed */
  /* Additional styling as needed */
}

.text-links-section p {
  margin-top: 5px;
  /* Reduce top margin for paragraphs */
  margin-bottom: 0px;
  /* Reduce bottom margin for paragraphs */
}

.text-links-section a {
  color: #1C75BC;
  /* Example hyperlink color */
  text-decoration: none;
  /* Optional: Removes underline from links */
}

.text-links-section a:hover {
  text-decoration: underline;
  /* Optional: Adds underline on hover */
}

.info {
  font-family: '小塚ゴシック Pr6N R', sans-serif;
  text-align: left;
  margin-top: 10px;
  padding-left: 8%;
  padding-right: 8%;
  margin-bottom: 0px;

}

.address a {
  color: #92668C;
  /*text-decoration: none*/
}

.phone a {
  color: #8DC63F;
  /*text-decoration: none*/
}

.web {
  color: #6D6E71;
}

/* Rest of your CSS */