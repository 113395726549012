body {
  font-family: '小塚ゴシック Pr6N R', sans-serif;
}

.container {
  max-width: 100%;
  margin: 0 auto;
  margin-bottom: 0px;
}

.descriptions {
  text-align: left;
  margin-top: 30px;
  margin-bottom: 10px;
  padding-left: 15px;
  /* Space below the avatar section */
}

.description-text {
  font-family: '小塚ゴシック Pr6N R', sans-serif;
  color: #92668C;
  font-size: 20px;
  margin-bottom: 5px;
}

.records {
  text-align: left;
  margin-top: 30px;
  margin-bottom: 10px;
  padding-left: 0px;
  /* Space below the avatar section */
}

.records-text {
  font-family: '小塚ゴシック Pr6N R', sans-serif;
  color: #127254;
  font-size: 20px;
  margin-bottom: 0px;
}

.records-terms {
  font-family: '小塚ゴシック Pr6N R', sans-serif;
  color: #127254;
  font-size: 15px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.job-options {
  display: flex;
  align-items: center;
}

.job-label {
  display: inline-block;
  padding: 5px 10px;
  cursor: pointer;
  margin-left: 10px;
  /* Space between radio options */
}

.job-optionJ1 {
  margin-bottom: 5px;
  display: block;
  padding-left: 0px;
  /* Ensures each radio option is on a new line */
}


.job-optionJ2 {
  margin-bottom: 5px;
  display: block;
  padding-left: 0px;
  /* Ensures each radio option is on a new line */
}

.job-optionJ1 input[type="radio"] {
  display: none;
}

.job-optionJ2 input[type="radio"] {
  display: none;
}

.job-optionJ1 input[type="radio"]+.job-label:before {
  content: '';
  position: absolute;
  left: 10px;
  top: 50%;
  /* Position inside the label */
  transform: translateY(-50%);
  height: 15px;
  width: 15px;
  /* Size of the circle */
  border: 2px solid #1C75BC;
  /* Color of your choice */
  border-radius: 50%;
  background-color: white;
}

.job-optionJ2 input[type="radio"]+.job-label:before {
  content: '';
  position: absolute;
  left: 10px;
  top: 50%;
  /* Position inside the label */
  transform: translateY(-50%);
  height: 15px;
  width: 15px;
  /* Size of the circle */
  border: 2px solid #94B56A;
  /* Color of your choice */
  border-radius: 50%;
  background-color: white;
}

.job-optionJ1 input[type="radio"]:checked+.job-label:before {
  background-color: #1C75BC;
  /* Same color as the border */
}

.job-optionJ2 input[type="radio"]:checked+.job-label:before {
  background-color: #94B56A;
  /* Same color as the border */
}

#optionJ1+.job-label {
  border-color: #1C75BC;
  color: #1C75BC;
}

#optionJ2+.job-label {
  border-color: #94B56A;
  color: #94B56A;
}

.job-label {
  display: inline-block;
  padding: 5px 60px;
  width: 40px;
  cursor: pointer;
  position: relative;
  padding-left: 35px;
  font-family: 'Futura', sans-serif;
  font-size: 20px;
  /* Increased font size */
  /* Space for the circle inside */
}

.job-input-container {
  padding-left: 15px;
  /* Styles for the overall container */
}

.job-row {
  margin-bottom: 15px;
  /* Space between rows */
  display: flex;

  /* Align label and input vertically */
}

.job-field1 {
  flex-basis: 48%;
  /* Adjust the width of each input field */
  display: flex;
  flex-direction: column;
}

.job-field2 {
  flex-basis: 48%;
  /* Adjust the width of each input field */
  display: flex;
  flex-direction: column;
}

.job-field3 {
  flex-basis: 48%;
  /* Adjust the width of each input field */
  display: flex;
  flex-direction: column;
}

.job-field4 {
  flex-basis: 48%;
  /* Adjust the width of each input field */
  display: flex;
  flex-direction: column;
}

.job-field1 label {
  margin-bottom: 5px;
  text-align: left;
  /* Aligns the text of the label to the left */
  display: block;
  font-family: '小塚ゴシック Pr6N R', sans-serif;
  color: #92668C;
  font-size: 15px;
}

.job-field2 label {
  margin-bottom: 5px;
  text-align: left;
  font-family: '小塚ゴシック Pr6N R', sans-serif;
  display: block;
  color: #835323;
  font-size: 15px;
}

.job-field3 label {
  margin-bottom: 5px;
  text-align: left;
  font-family: '小塚ゴシック Pr6N R', sans-serif;
  display: block;
  color: #127254;
  font-size: 15px;
}

.job-field4 label {
  margin-bottom: 5px;
  text-align: left;
  font-family: '小塚ゴシック Pr6N R', sans-serif;
  display: block;
  color: #EA6565;
  font-size: 15px;
}

.job-field1 input {
  width: 80%;
  padding: 8px;
  border: 2px solid #92668C;
  /* Example border style */
  border-radius: 0px;
  color: #92668C;
  height: 20px;
  font-size: 18px;
  width: 90%;
  /* Fixed height for input fields */
  /* Example border radius */
}

.job-field2 input {
  width: 80%;
  padding: 8px;
  border: 2px solid #835323;
  /* Example border style */
  border-radius: 0px;
  color: #835323;
  height: 20px;
  font-size: 18px;
  width: 90%;
  /* Example border radius */
}

.job-field3 input {
  width: 80%;
  padding: 8px;
  border: 2px solid #127254;
  color: #127254;
  height: 20px;
  /* Example border style */
  border-radius: 0px;
  font-size: 18px;
  width: 90%;
  /* Example border radius */
}

.job-field4 input {
  width: 80%;
  padding: 8px;
  border: 2px solid #EA6565;
  color: #EA6565;
  height: 20px;
  /* Example border style */
  border-radius: 0px;
  font-size: 18px;
  width: 90%;
  /* Example border radius */
}