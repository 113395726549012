body {
  font-family: 'Arial', sans-serif;
}

.container {

  max-width: 600px;
  margin: 0 auto;
  margin-bottom: 0px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.avatars {
  display: flex;
  justify-content: space-between;
  /* Distribute space between items */
  align-items: center;
  padding: 0px;
  /* Adjust padding to control distance from the container edges */
  margin-top: 15px;
  margin-bottom: 10px;
  margin-left: 5%;
  margin-right: 5%;
  /* Space from the upper div */
}

.avatar {
  width: calc(50% - 10px);
  /* Subtract the desired space between the images */
  height: auto;
  /* Maintain aspect ratio */
  margin: 0 0px;

  /* Add 
  margin to create space between the images */
}

.year-input-container {
  margin-top: 10px;
  margin-bottom: 0px;
  padding-left: 15px;
  width: 25%;
  display: block;
  display:flex;
  flex-direction: row
  /* Styles for the overall container */
}

.year-field {
  margin-bottom: 15px;
  /* Space between rows */
  display: flex;
}

.year-field label {
  padding-left: 10px;
 
  text-align: left;
  font-family: '小塚ゴシック Pr6N R', sans-serif;
  display: block;
  color: #92668C;
  font-size: 24px;
}



.year-field input {
  width: 20%;
  padding: 8px;
  border: 2px solid #92668C;
  /* Example border style */
  border-radius: 0px;
  color: #92668C;
  height: 20px;
  font-size: 18px;
  width: 90%;
  /* Fixed height for input fields */
  /* Example border radius */
}

.mortage-content-container {
  display: flex;
  margin-bottom: 0px;
}

.mortage-part2-container{
  width: 45%;
  /* Each section takes up 50% of the container */
  padding: 0px;
  /* Additional styling as needed */
}

.mortage-image-container {
  width: 55%;
  max-width: 250px;
  /* Each section takes up 50% of the container */
  padding-left: 15px;
  /* Additional styling as needed */

}

.mortage-image-container img {
  margin-top: 0px;
  width: 100%;
  position: relative;
  top: -30px;
  /* Position 10px from the top of the container */
  left: 0px;
  margin-right: 20%;
  /* Makes the image responsive */
  height: auto;
  /* Maintains aspect ratio */
  /* Makes the image responsive */

  /* Maintains aspect ratio */
}

.custom-file-uploadm1 {
  cursor: pointer;
  /* Additional styling for the label */
}

.custom-file-uploadm2 {
  cursor: pointer;
  /* Additional styling for the label */
}

.custom-file-uploadm3 {
  cursor: pointer;
  /* Additional styling for the label */
}

.custom-file-uploadm1 img {
  max-width: 100%;
  /* Maximum width of the image button */
  height: auto;
  /* Maintain the aspect ratio of the image */
  display: block;
  margin-top: 0px;
  margin-bottom: 10px;
  padding-left: 15px;
}

.mortage-content-container-2 {
  display: flex;
  margin-bottom: 0px;
}

.mortage-part2-container-a{
  width: 40%;
  /* Each section takes up 50% of the container */
  padding-left: 5px;
  /* Additional styling as needed */
}

.mortage-part2-container-b{
  width: 40%;
  /* Each section takes up 50% of the container */
  padding-left: 10%;
  /* Additional styling as needed */
}

.description-text-address {
  font-family: '小塚ゴシック Pr6N R', sans-serif;
  color: #1C75BC;
  font-size: 20px;
  margin-bottom: 5px;

  /* Space between each text if needed */
}

.description-text-proof {
  font-family: '小塚ゴシック Pr6N R', sans-serif;
  color: #92668C;
  font-size: 20px;
  margin-bottom: 5px;

  /* Space between each text if needed */
}

.custom-file-uploadm2 {
  cursor: pointer;
  /* Additional styling for the label */
}

.custom-file-uploadm2 img {
  max-width: 85%;
  /* Maximum width of the image button */
  height: auto;
  /* Maintain the aspect ratio of the image */
  display: block;
  margin-top: 0px;
  margin-bottom: 10px;
  padding-left: 15px;
}

.descriptionsm2 {
  text-align: left;
  margin-top: 0px;
  margin-bottom: 0;
  padding-left: 15px;
  /* Space below the avatar section */
}