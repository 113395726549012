body {
  font-family: 'Arial', sans-serif;
}

.container {

  max-width: 600px;
  margin: 0 auto;
  margin-bottom: 0px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.avatars {
  display: flex;
  justify-content: space-between;
  /* Distribute space between items */
  align-items: center;
  padding: 0px;
  /* Adjust padding to control distance from the container edges */
  margin-top: 15px;
  margin-bottom: 10px;
  margin-left: 5%;
  margin-right: 5%;
  /* Space from the upper div */
}

.avatar {
  width: calc(50% - 10px);
  /* Subtract the desired space between the images */
  height: auto;
  /* Maintain aspect ratio */
  margin: 0 0px;

  /* Add margin to create space between the images */
}

.descriptions {
  text-align: left;
  margin-top: 30px;
  margin-bottom: 0;
  padding-left: 15px;
  /* Space below the avatar section */
}

.description-text {
  font-family: '小塚ゴシック Pr6N R', sans-serif;
  color: #92668C;
  font-size: 20px;
  margin-bottom: 10;

  /* Space between each text if needed */
}

.descriptions2 {
  text-align: left;
  margin-top: 30px;
  margin-bottom: 0;
  padding-left: 15px;
  /* Space below the avatar section */
}

.description2-text {
  font-family: '小塚ゴシック Pr6N R', sans-serif;
  color: #127254;
  font-size: 20px;
  margin-bottom: 5;
  /* Space between each text if needed */
}

.additional-text {
  text-align: left;
  margin-top: 5px;
  padding-left: 15px;
  /* No space above this div */
}

.additional-text p {
  font-family: '小塚ゴシック Pr6N R', sans-serif;
  color: #808285;
  margin-top: 0;
  /* No space at the top of the paragraph */
  margin-bottom: 5px;
  /* No space at the bottom of the paragraph */
}

.terms p {
  font-family: '小塚ゴシック Pr6N R', sans-serif;
  color: #808285;
  margin-top: 0;
  /* No space at the top of the paragraph */
  margin-bottom: 5px;
  font-size: 12px;
  padding-left: 15px;
  /* No space at the bottom of the paragraph */
}

.content-container {
  display: flex;

  justify-content: space-between;
}

.content-container2 {
  display: flex;
  margin-bottom: 0px;
}

.parent-div {
  display: flex;
  /* Enables Flexbox layout */
}

.replay-options1 {
  width: 45%;
  /* Each section takes up 50% of the container */
  padding: 0px;
  /* Additional styling as needed */
}

.image-container1 {
  width: 55%;
  max-width: 250px;
  /* Each section takes up 50% of the container */
  padding: 0px;
  /* Additional styling as needed */
}

.image-container1 img {
  margin-top: 0px;
  width: 100%;
  /* Makes the image responsive */

  /* Maintains aspect ratio */
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin-bottom: 0px;
  /* Additional styling as needed */
}

.footer-left,
.footer-right {
  display: flex;
  align-items: center;
}

.footer-button-image {
  width: 50px;
  /* Adjust as needed */
  height: auto;
  /* Maintain aspect ratio */
  cursor: pointer;
}

/* Specific styling for spacing between back and next buttons */
.footer-right img {
  margin-right: 10px;
  /* Adjust this value as needed for spacing */
}

/* Add any specific styling for the left and right side if needed */