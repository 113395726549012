body {
  font-family: '小塚ゴシック Pr6N R', sans-serif;
}

.container {
  max-width: 100%;
  margin: 0 auto;
  margin-bottom: 0px;
}

.descriptions {
  text-align: left;
  margin-top: 30px;
  margin-bottom: 10px;
  padding-left: 15px;
  /* Space below the avatar section */
}

.description-text {
  font-family: '小塚ゴシック Pr6N R', sans-serif;
  color: #92668C;
  font-size: 20px;
  margin-bottom: 5px;

  /* Space between each text if needed */
}

.descriptions-address {
  text-align: left;
  margin-top: 30px;
  margin-bottom: 10px;
  padding-left: 15px;
  /* Space below the avatar section */
}

.description-address {
  font-family: '小塚ゴシック Pr6N R', sans-serif;
  color: #127254;
  font-size: 20px;
  margin-bottom: 5px;

  /* Space between each text if needed */
}

.s3-upload-container {
  width: 80%;
}

.step3-form {
  font-family: '小塚ゴシック Pr6N R', sans-serif;
  font-size: 15px;
  text-align: left;
  /* Aligns all content in the form to the left */
}

.custom-file-upload {
  cursor: pointer;
  /* Additional styling for the label */
}

.custom-file-upload img {
  max-width: 100%;
  /* Maximum width of the image button */
  height: auto;
  /* Maintain the aspect ratio of the image */
  display: block;
  margin-top: 0px;
  padding-left: 15px;
}


.radio-option {
  margin-bottom: 5px;
  display: block;
  /* Ensures each radio option is on a new line */
}

.radio-option1 {
  margin-bottom: 5px;
  display: block;
  padding-left: 15px;
  /* Ensures each radio option is on a new line */
}

.radio-option2 {
  margin-bottom: 5px;
  display: block;
  padding-left: 15px;
  /* Ensures each radio option is on a new line */
}

.radio-option3 {
  margin-bottom: 5px;
  display: block;
  padding-left: 15px;
  /* Ensures each radio option is on a new line */
}

.radio-option4 {
  margin-bottom: 5px;
  display: block;
  padding-left: 15px;
  /* Ensures each radio option is on a new line */
}

.radio-option5 {
  margin-bottom: 5px;
  display: block;
  padding-left: 15px;
  /* Ensures each radio option is on a new line */
}

.radio-option6 {
  margin-bottom: 5px;
  display: block;
  padding-left: 15px;
  /* Ensures each radio option is on a new line */
}

.radio-option7 {
  margin-bottom: 5px;
  display: block;
  padding-left: 15px;
  /* Ensures each radio option is on a new line */
}


.radio-label {
  display: inline-block;
  padding: 5px 60px;
  width: 200px;

  /*box-sizing: border-box;*/
  /* Fixed width for each label */
  /* Adjust padding as needed */
  border: 2px solid;
  /* Default border, color will be set individually */
  /* Rounded corners */
  cursor: pointer;
  position: relative;
  padding-left: 35px;
  font-family: 'Futura', sans-serif;
  font-size: 20px;
  /* Increased font size */
  /* Space for the circle inside */
}

/* Styling for each option */
#option1+.radio-label {
  border-color: #1C75BC;
  color: #1C75BC;
}

#option2+.radio-label {
  border-color: #94B56A;
  color: #94B56A;
}

#option3+.radio-label {
  border-color: #92668C;
  color: #92668C;
}

#option4+.radio-label {
  border-color: #DEBED1;
  color: #DEBED1;
}

#option5+.radio-label {
  border-color: #127254;
  color: #127254;
}

#option6+.radio-label {
  border-color: #EA6565;
  color: #EA6565;
}

#option7+.radio-label {
  border-color: #835323;
  color: #835323;
}

/* Hide the default radio button */
.radio-option input[type="radio"] {
  display: none;
}

.radio-option1 input[type="radio"] {
  display: none;
}

.radio-option2 input[type="radio"] {
  display: none;
}

.radio-option3 input[type="radio"] {
  display: none;
}

.radio-option4 input[type="radio"] {
  display: none;
}

.radio-option5 input[type="radio"] {
  display: none;
}

.radio-option6 input[type="radio"] {
  display: none;
}

.radio-option7 input[type="radio"] {
  display: none;
}


/* Custom radio button inside the label */
.radio-option1 input[type="radio"]+.radio-label:before {
  content: '';
  position: absolute;
  left: 10px;
  top: 50%;
  /* Position inside the label */
  transform: translateY(-50%);
  height: 15px;
  width: 15px;
  /* Size of the circle */
  border: 2px solid #1C75BC;
  /* Color of your choice */
  border-radius: 50%;
  background-color: white;
}

.radio-option2 input[type="radio"]+.radio-label:before {
  content: '';
  position: absolute;
  left: 10px;
  top: 50%;
  /* Position inside the label */
  transform: translateY(-50%);
  height: 15px;
  width: 15px;
  /* Size of the circle */
  border: 2px solid #94B56A;
  /* Color of your choice */
  border-radius: 50%;
  background-color: white;
}

.radio-option3 input[type="radio"]+.radio-label:before {
  content: '';
  position: absolute;
  left: 10px;
  top: 50%;
  /* Position inside the label */
  transform: translateY(-50%);
  height: 15px;
  width: 15px;
  /* Size of the circle */
  border: 2px solid #92668C;
  /* Color of your choice */
  border-radius: 50%;
  background-color: white;
}

.radio-option4 input[type="radio"]+.radio-label:before {
  content: '';
  position: absolute;
  left: 10px;
  top: 50%;
  /* Position inside the label */
  transform: translateY(-50%);
  height: 15px;
  width: 15px;
  /* Size of the circle */
  border: 2px solid #DEBED1;
  /* Color of your choice */
  border-radius: 50%;
  background-color: white;
}

.radio-option5 input[type="radio"]+.radio-label:before {
  content: '';
  position: absolute;
  left: 10px;
  top: 50%;
  /* Position inside the label */
  transform: translateY(-50%);
  height: 15px;
  width: 15px;
  /* Size of the circle */
  border: 2px solid #127254;
  /* Color of your choice */
  border-radius: 50%;
  background-color: white;
}


.radio-option6 input[type="radio"]+.radio-label:before {
  content: '';
  position: absolute;
  left: 10px;
  top: 50%;
  /* Position inside the label */
  transform: translateY(-50%);
  height: 15px;
  width: 15px;
  /* Size of the circle */
  border: 2px solid #EA6565;
  /* Color of your choice */
  border-radius: 50%;
  background-color: white;
}

/* Style when radio is checked */
.radio-option1 input[type="radio"]:checked+.radio-label:before {
  background-color: #1C75BC;
  /* Same color as the border */
}

.radio-option2 input[type="radio"]:checked+.radio-label:before {
  background-color: #94B56A;
  /* Same color as the border */
}

.radio-option3 input[type="radio"]:checked+.radio-label:before {
  background-color: #92668C;
  /* Same color as the border */
}

.radio-option4 input[type="radio"]:checked+.radio-label:before {
  background-color: #DEBED1;
  /* Same color as the border */
}

.radio-option5 input[type="radio"]:checked+.radio-label:before {
  background-color: #127254;
  /* Same color as the border */
}

.radio-option6 input[type="radio"]:checked+.radio-label:before {
  background-color: #EA6565;
  /* Same color as the border */
}


.content-container {
  display: flex;

  justify-content: space-between;
}

.replay-options {
  width: 45%;
  /* Each section takes up 50% of the container */
  padding: 0px;
  /* Additional styling as needed */
}

.image-container {
  width: 50%;
  margin-right: 5%;
  /* Each section takes up 50% of the container */
  padding: 0px;
  /* Additional styling as needed */
}

.image-container img {
  width: 85%;
  margin-right: 10%;
  /* Makes the image responsive */
  height: auto;
  /* Maintains aspect ratio */
}