body {
  font-family: '小塚ゴシック Pr6N R', sans-serif;
}

.container {
  max-width: 100%;
  margin: 0 auto;
  margin-bottom: 0px;
}

.phone-input-container {
  margin-top: 40px;
  margin-bottom: 0px;
  padding-left: 15px;
  width: 80%;
  /* Styles for the overall container */
}

.fps-input-container {
  margin-top: 40px;
  margin-bottom: 40px;
  padding-left: 15px;
  /* Styles for the overall container */
}

.phone-row {
  margin-bottom: 15px;
  /* Space between rows */
  display: flex;

  /* Align label and input vertically */
}

.fps-row {
  margin-bottom: 15px;
  /* Space between rows */
  display: flex;

  /* Align label and input vertically */
}

.fps-field1 {
  flex-basis: 48%;
  /* Adjust the width of each input field */
  display: flex;
  flex-direction: column;
}

.phone-field1 {
  flex-basis: 48%;
  /* Adjust the width of each input field */
  display: flex;
  flex-direction: column;
}

.phone-field1 label {
  margin-bottom: 5px;
  text-align: left;
  /* Aligns the text of the label to the left */
  display: block;
  font-family: '小塚ゴシック Pr6N R', sans-serif;
  color: #92668C;
  font-size: 22px;
}

.fps-field1 label {
  margin-bottom: 5px;
  text-align: left;
  font-family: '小塚ゴシック Pr6N R', sans-serif;
  display: block;
  color: #127254;
  font-size: 22px;
}

.phone-field1 input {
  width: 80%;
  padding: 8px;
  border: 2px solid #92668C;
  /* Example border style */
  border-radius: 0px;
  color: #92668C;
  height: 20px;
  font-size: 18px;
  width: 90%;
  /* Fixed height for input fields */
  /* Example border radius */
}

.fps-field1 input {
  width: 80%;
  padding: 8px;
  border: 2px solid #127254;
  /* Example border style */
  border-radius: 0px;
  color: #127254;
  height: 20px;
  font-size: 18px;
  width: 90%;
  /* Example border radius */
}

.step5-image-container img {
  position: relative;
  top: 0px;
  left: 0px;
  width: 100%;
  margin-right: 20%;
  height: auto;
}

.downArrow-image-container {
  margin-top: 0px;
}

.downArrow-image-container img {
  position: relative;
  top: -15px;
  left: -35%;
  width: 8%;
  margin-right: 0%;
  height: auto;

}

.fps-container {
  margin-top: 0px;
  display: flex;
}

.copy-image-container {
  width: 20%;
  height: 100%;
  padding: 0px;
}

.rightArrow-image-container {
  width: 20%;
  padding: 0px;
}

.fps-input-container {
  position: relative;
  width: 60%;
  margin-top: -10%;
  margin-left: -8%;
  height: auto;
}


.copy-image-container img {
  position: relative;
  top: -17px;
  left: 18%;
  width: 90%;
  margin-right: 0%;
  height: auto;
}

.rightArrow-image-container img {
  position: relative;
  top: -15px;
  left: -7%;
  width: 60%;
  margin-right: 0%;
  height: auto;
}