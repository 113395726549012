.income-container {
  display: flex;

  justify-content: space-between;
}

.income-upload {

  /* Aligns the image to the left */
  width: 35%;
  /* Each section takes up 50% of the container */
  padding: 0px;
  /* Additional styling as needed */
  margin-bottom: 0%;
}

.step4-image-container {
  width: 75%;
  margin-right: 0%;
  /* Each section takes up 50% of the container */
  padding: 0px;
  /* Additional styling as needed */
}


.income-upload-container img {
  position: relative;
  top: 10px;
  /* Position 10px from the top of the container */
  left: 0px;
  /* Position 10px from the left of the container */
  width: 100%;
  padding: 0px;
}

.step4-image-container img {
  position: relative;
  top: -10px;
  /* Position 10px from the top of the container */
  left: 0px;
  /* Position 10px from the left of the container */
  width: 100%;
  margin-right: 20%;
  /* Makes the image responsive */
  height: auto;
  /* Maintains aspect ratio */
}

.descriptions-income {
  text-align: left;
  margin-top: 00px;
  margin-bottom: 00px;
  padding-left: 0px;
}

.description-income {
  font-family: '小塚ゴシック Pr6N R', sans-serif;
  color: #94B56A;
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 0px;

  /* Space between each text if needed */
}